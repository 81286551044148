.main {
	.trigger {
		cursor: pointer;
		background-color: transparent;
		width: 38px;
		height: 38px;
		border-radius: 50%;
		background: #000;

		@media screen and (min-width: 768px) {
			width: 48px;
			height: 48px;
		}

		.triggerImg {
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}
	}
	

	.sm {
		width: 30px;
		height: 30px;
	}

	.md {
		width: 38px;
		height: 38px;
	}

	.lg {
		width: 58px;
		height: 58px;
	}

	@media screen and (min-width: 768px) {
		.sm {
			width: 36px;
			height: 36px;
		}

		.md {
			width: 48px;
			height: 48px;
		}

		.lg {
			width: 68px;
			height: 68px;
		}
	}
}
