.main {
	.citationContainer {
		height: max-content;
		overflow-y: auto;
		padding-block: 15px;
		scrollbar-width: none;
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
			display: none;
		}
	}
}
