@use "../../scss/utils/functions" as fn;
@use "../../scss/global/colors" as *;

.modal {
	position: fixed;
	inset: 0;
	z-index: 100;
	pointer-events: none;
	z-index: 10000006 !important;

	.modalContainer {
		position: fixed;
		right: 0;
		height: 100%;
		z-index: 10000006 !important;

		.hidden {
			pointer-events: none;
		}

		.modalContent {
			transition: all 350ms ease-in-out;
			opacity: 0;
			transform: translateX(100%);
			height: 100%;
		}

		.active {
			transform: translateX(0);
			opacity: 1;
		}
	}
}


